/* -------------------- General */

html {
    scroll-behavior: smooth
}

.wrapper {
    max-width: 90%;
    margin: 0 auto;
}

.mainTitle {
    color: black;
    font-family: Ubuntu, 'sans-serif';
    margin-bottom: 3%;
    font-size: 2.8vw;
    font-weight: bold;
}

.filledButton {
    font-family: Ubuntu, 'sans-serif';
    font-size: 1.5vw;
    padding: 2vh 2vw;
    border-radius: 3px;
    background: #00aee6;
    color: white;
    border: none;
}

.filledButton:hover {
    background: #0198cc;
}

.frameButton {
    font-family: Ubuntu, 'sans-serif';
    font-size: 1.5vw;
    padding: 2vh 5vw;
    background: white;
    color: #00aee6;
    border: 2px solid #00aee6;
}

.frameButton:hover {
    background: #23c6ff;
    color: #ffffff;
}

.descriptionText {
    color: black;
    opacity: 90%;
    line-height: 3vw;
    font-size: 2vw;
    font-family: Ubuntu, 'sans-serif';
}

/* -------------------- Header */

header {
    position: absolute;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 2% 0;
    top: 0;
    margin-left: 50%;
    transform: translate(-50% ,0);
}

.headerLogo {
    max-width: 20%;
}

.headerLogoPicture {
    max-width: 100%;
}

.headerContactBlock {
    padding: 1% 1%;
    display: flex;
    align-items: center;
    border-radius: 3px;
    background: white;
    margin-right: 2%;
    max-width: 45%;
}

.headerPhone {
    margin-right: 1vw;
    font-family: Ubuntu, 'sans-serif';
    font-size: 1.5vw;
}

.headerPhone a {
    color: black;
}

/* -------------------- Main Section */

.mainSection {
    margin-bottom: 6%;
}

.mainSectionContentContainer {
    display: flex;
    justify-content: space-between;
}

.mainSectionDescriptionContainer {
    padding-top: 13%;
    max-width: 45%;
}

.mainSectionTextContainer {
    margin-bottom: 5%;
}

.mainSectionKombinePicture {
    max-width: 50%;
    object-fit: cover;
    border-radius: 0 0 7px 7px;
}

/* -------------------- Description Section */

.descriptionSection {

}

.descriptionSectionContentContainer {
    display: flex;
    justify-content: space-between;
}

.descriptionSectionTextContainer {
    margin-top: 2%;
    box-sizing: border-box;
    max-width: 45%;
}

.descriptionSectionTextContainer .descriptionText:first-of-type {
    margin-bottom: 3%;
}

.descriptionSectionKombinePicture {
    max-width: 50%;
    border-radius: 7px;
    object-fit: cover;
}

/* -------------------- Partners Section */

.partnersSection {
    margin-bottom: 7%;
}

.partnersSectionContentContainer {
    display: flex;
    justify-content: space-between;
}

.partnersSectionCardItem {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border-top: 9px solid #00AEE6;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,11%);
    padding: 0 2%;
    margin-right: 2%;
}

.partnersSectionCardItem:last-child {
    margin-right: 0;
}

.partnersSectionLogoPicture {
    max-width: 85%;
    max-height: 70%;
}

/* -------------------- Products Section */

.productsSection {
    margin-bottom: 7%;
}

.productsSection .mainTitle {
    text-align: center;
}

.productsSectionBackgroundContainer {
    background: #F5F5F5;
    padding: 3%;
}

.productsSectionContentContainer {
    text-align: center;
}

.productsSectionProductContentContainer {
    margin-bottom: 4%;
    cursor: pointer;
    height: auto;
    background-color: white;
}

.productsSectionItemNameBlock {
    font-family: Ubuntu, 'sans-serif';
    font-size: 2vw;
    position: absolute;
    box-sizing: border-box;
    padding: 5% 5%;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: rgba(3, 3, 0, 60%);
}

.productsSectionItemName {
    color: white;
}

.productsSectionItem:last-child {
    margin-right: 0;
}

.productsSectionProductPicture {
    width: 100%;
}

/* -------------------- Contact Form Section */

.formSection {
    padding-top: 7%;
    margin-bottom: 7%;
}

.formSectionContentContainer {
    display: flex;
    justify-content: space-between;
    gap: 5%;
}

.formSectionContactForm {
    max-width: 45%;
}

.formSectionInput {
    width: 100%;
    color: white;
    border: 0;
    border-bottom: 1px solid rgba(3, 3, 0, 50%);
    padding-bottom: 3%;
    font-family: Ubuntu, 'sans-serif';
    font-size: 2vw;
    color: rgba(0, 0, 0, 70%);
    margin-bottom: 10%;
    resize: none;
}

.formSectionInput::placeholder {
    color: rgba(3, 3, 0, 50%);
}

.formSectionCustomerAgreement {
    font-size: 1.2vw;
    font-family: Ubuntu, 'sans-serif';
    margin-bottom: 2%;
    color: rgba(3, 3, 0, 70%);
}

.nameIncorrect,
.phoneIncorrect,
.emailIncorrect,
.descriptionIncorrect {
    color: rgb(239, 68, 68);
    border-bottom: 1px solid rgb(239, 68, 68);
}

.nameIncorrect::placeholder,
.phoneIncorrect::placeholder,
.emailIncorrect::placeholder,
.descriptionIncorrect::placeholder {
    color: rgba(239, 68, 68, 70%);
}

.formSectionCustomerAgreementLink {
    color: #0198cc;
}

.formSectionDescriptionBlock {
    background: rgba(198, 242, 255, 0.4);
    width: 50%;
}

/* -------------------- Footer */

footer {
    background: rgba(0, 0, 0, 70%);
    color: #FFFFFF;
    padding: 2% 0;
}

.footerContentContainer {
    display: flex;
    justify-content: space-between;
    gap: 2%;
    padding: 2% 0;
}

.footerContacts,
.footerSchedule,
.footerAddress {
    flex: 1;
}

.footerDescriptionValue,
.footerDescriptionValueLink {
    margin-bottom: 5%;
    font-family: Ubuntu, 'sans-serif';
    font-size: 1.5vw;
    color: rgba(255, 255, 255, 80%);
}

.footerDescriptionValueLink {
    text-decoration: underline;
}

footer .mainTitle {
    color: #FFFFFF;
    margin-bottom: 7%;
}

/* -------------------- Products table */

.productsTableSection {
    padding: 10% 0 2% 0;
}

.productsTableContentContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    grid-gap: 2rem;
}

.productsTableContentBlock {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border-radius: 7px;
}

.productsTableContentBlockImage {
    width: 80%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 1.7vw;
}

.productsTableContentItemText {
}

.productsTableContentItemName {
    color: black;
    opacity: 90%;
    line-height: 3vw;
    font-size: 2.2vw;
    font-family: Ubuntu, 'sans-serif';
    font-weight: bold;
    margin-bottom: 1vw;
}

.productsTableContentItemDescription {
    color: rgba(3, 3, 0, 70%);;
    opacity: 90%;
    line-height: 2vw;
    font-size: 1.3vw;
    font-family: Ubuntu, 'sans-serif';
    margin-bottom: 1.2vw;
    padding: 4px;

    height: 10vw;
    overflow-y: scroll;
    border: 1px solid #b6eeff;
    border-radius: 5px;
}

.productsTableContentItemPrice {
    color: black;
    opacity: 90%;
    line-height: 1.7vw;
    font-size: 2vw;
    font-family: Ubuntu, 'sans-serif';
    font-weight: bold;
    margin-bottom: 1.2vw;
}

/* -------------------- Popup */

.popup {
    position: fixed;
    top: 2vh;
    left: 2vw;
    padding: 2vw;
    font-size: 1.5vw;
    z-index: 1000;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,11%);
    font-family: Ubuntu, sans-serif;
    display: none;
}

.popupVisible {
    display: block;
}

.popupSuccess {
    border-top: 5px solid #379432;
}

.popupError {
    border-top: 5px solid rgb(239, 68, 68);;
}

@media (max-width: 425px) {
    /* -------------------- General */

    .mainTitle {
        margin-bottom: 5%;
        font-size: 8vw;
        text-align: center;
    }

    .descriptionText {
        line-height: 8vw;
        font-size: 5.5vw;
    }

    .filledButton {
        font-family: Ubuntu, 'sans-serif';
        font-size: 5vw;
        padding: 1.5vh 3vw;
        border-radius: 3px;
        background: #00aee6;
        color: white;
        border: none;
    }

    .frameButton {
        font-family: Ubuntu, 'sans-serif';
        font-size: 5vw;
        padding: 1.5vh 3vw;
        background: white;
        color: #00aee6;
        border: 2px solid #00aee6;
    }

    /* -------------------- Header */

    header {
        position: static;
        display: block;
        margin-bottom: 7%;
    }

    .headerLogoPicture {
        margin-bottom: 7%;
        max-width: 50%;
    }

    .headerContactBlock {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 100%;
        background: #d3efff;
        padding: 5%;
    }

    .headerPhone {
        font-size: 6vw;
        margin: 0 0 3% 0;
    }

    /* -------------------- Main Section */

    .mainSection {
        margin-bottom: 15%;
    }

    .mainSectionContentContainer {
        display: flex;
        flex-direction: column;
    }

    .mainSectionKombinePicture {
        min-width: 100%;
        min-height: 18vw;
        border-radius: 7px;
    }

    .mainSectionDescriptionContainer {
        padding-top: 0;
        max-width: 100%;
        margin-bottom: 5%;
    }

    /* -------------------- Products Section */

    .productsSection {
        margin-bottom: 15%;
    }

    .productsSectionButtonContainer {
        text-align: center;
    }

    .productsSectionItemNameBlock {
        font-size: 5.5vw;
        height: 20%;
    }

    .productsSectionItemName {
        font-size: 4vw;
    }

    /* -------------------- Description Section */

    .descriptionSection {
        margin-bottom: 15%;
    }

    .descriptionSectionContentContainer {
        display: flex;
        flex-direction: column;
    }

    .descriptionSectionKombinePicture {
        order: 1;
        min-width: 100%;
        border-radius: 7px;
    }

    .descriptionSectionTextContainer {
        order: 0;
        max-width: 100%;
        margin-bottom: 5%;
    }

    .descriptionSectionTextContainer .descriptionText:first-of-type {
        margin-bottom: 5%;
    }

    /* -------------------- Partners Section */

    .partnersSection {
        margin-bottom: 15%;
    }

    .partnersSectionContentContainer {
        justify-content: space-around;
        flex-wrap: wrap;
        min-height: 30vw;
    }

    .partnersSectionCardItem {
        max-width: 40%;
        margin-right: 0;
        padding: 0 2%;
        margin-bottom: 5%;
    }

    .partnersSectionCardItem:last-child {
        margin-right: 0;
    }

    /* -------------------- Contact Form Section */

    .formSection {
        margin-bottom: 15%;
    }

    .formSectionContentContainer {
        flex-direction: column;
    }

    .formSectionContactForm {
        min-width: 100%;
        margin-bottom: 5%;
    }

    .formSectionInput {
        font-size: 5.5vw;
    }

    .formSectionCustomerAgreement {
        font-size: 4vw;
        line-height: 5.5vw;
    }

    .formSectionDescriptionBlock {
        height: 200px;
        width: 100%;
    }

    /* -------------------- Footer */

    .footerContentContainer {
        display: block;
        padding: 10% 0;
    }

    .footerContacts,
    .footerSchedule,
    .footerAddress {
        margin-bottom: 10%;
    }

    .footerContacts .mainTitle,
    .footerSchedule .mainTitle,
    .footerAddress  .mainTitle {
        text-align: start;
        font-size: 8vw;
        margin-bottom: 5%;
    }

    .footerDescriptionValue,
    .footerDescriptionValueLink {
        font-size: 5.5vw;
    }

    /* -------------------- Products table */

    .productsTableSection {
        padding: 3% 0 2% 0;
    }

    .productsTableContentBlockImage {
        width: 100%;
        margin-bottom: 5vw;
    }

    .productsTableContentContainer {
        grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    }

    .productsTableContentItemName {
        line-height: 3vw;
        font-size: 5vw;
        margin-bottom: 5vw;
    }

    .productsTableContentItemDescription {
        line-height: 6vw;
        font-size: 4vw;
        margin-bottom: 1.2vw;

        height: 23vw;
    }

    .productsTableContentItemPrice {
        line-height: 6vw;
        font-size: 6vw;
        margin-bottom: 5vw;
    }

    /* -------------------- Popup */

    .popup {
        padding: 4vw;
        font-size: 4vw;
    }
}

@media (min-width: 425px) and (max-width: 768px){

}

@media (min-width: 768px) and (max-width: 1024px){

}

@media (min-width: 1024px) {

}
